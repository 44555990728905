<template>
  <li class="accordion__item">
    <div 
      class="accordion__trigger"
      :class="{'accordion__trigger_active': visible}"
      @click="open">

      <!-- This slot will handle the title/header of the accordion and is the part you click on -->
        <slot name="accordion-trigger"></slot>
        <i class="material-icons-round accordion-icon" :class="{'accordion-icon-active': visible}">expand_more</i>
    </div>
    <!-- <div class="accordion_icon" :class="{'accordion-icon-active': visible}">
        <slot name="accordion-icon"></slot> 
    </div> -->

    <transition 
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end">

      <div class="accordion__content"
        v-show="visible">
        <ul>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <slot name="accordion-content"></slot>
          <div class="empty" style="height: 10px; width: 100%;"></div>
        </ul>
      </div>
    </transition>
  </li>
</template>


<script>
export default {
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
  created() {
    this.index = this.Accordion.count++;
  }
};
</script>

<style scoped>
.accordion__item {
  cursor: pointer;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__item:last-child {
  border-bottom: none;
}

.accordion__trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 7px 0 7px 0;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}

.accordion__content {
    white-space: pre-line;
    padding: 0 15px 0 0;
}

.accordion-icon-active {
    transform: rotate(180deg);
    transition: 0.3s ease;
}
</style>

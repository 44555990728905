<template>
  <div>
    <v-style v-if="modal_open">
      body { overflow: hidden !important; position: relative !important;
      padding-left: 0px; padding-top: 0px; padding-right: 0px; margin-left: 0;
      margin-top: 0; margin-right: 0px !important; }
    </v-style>
    <div class="pc">
      <div id="header" class="header">
        <div class="container">
          <div class="logo-pc" style="display: none">
            <img src="../../public/img/Logo-PC.svg" alt="" />
          </div>
          <div class="znak_header"></div>
          <div class="img_header"></div>
          <div class="rating_pc" style="display: none">
            <div class="rating_pc_title">Стоматология г.Тында</div>
            <div class="rating_pc_data">
              <div class="phone_pc_title">Приём взрослых и детей</div>
              <!-- <span class="rating_pc_avarage">{{ rating }}
              </span>
              <i
                class="material-icons-round"
                style="
                  color: #ffda25;
                  font-size: 19px;
                  line-height: 15px;
                  margin-right: 5px;
                "
                >star</i
              >
              <span class="rating_point">•</span>
              <span class="rating_pc_count">{{ countStar }} {{ $sklonyator(countStar, ['оценка', 'оценки', 'оценок']) }}</span> -->
            </div>
          </div>
          <a href="https://t.me/kvokkastom" class="insta"></a>
          <div class="phone_pc" style="display: none">
            <div class="phone_pc_number">8 800 200-27-05</div>
            <div class="phone_pc_title">Звонок бесплатный</div>
          </div>
          <a href="tel:88002002705" class="phone">
            <i class="material-icons-round phone_icon">phone_enabled</i>
          </a>
        </div>
      </div>
      <div class="probel"></div>
      <div class="section_mobile_slider">
        <flicking v-if="loading_banner_complete"  :options="flickerOptions">
          <div @click="modalOpen(banner)" v-for="banner in banners_header" :key="banner.id" class="panel">
            <img :src="banner.link_pic_mobile" alt="" />
          </div>
        </flicking>
      </div>
      <div class="section-pc-slider" v-if="loading_banner_complete">
        <flicking  class="" :options="flickerOptionsPC" :plugins="plugins_pc">
          <div @click="modalOpen(banner)" v-for="banner in banners_header" :key="banner.id" class="panel-pc">
            <img :src="banner.link_pic_pc" alt="" />
          </div>
          <!-- <span slot="viewport" class="flicking-arrow-prev is-circle"></span>
          <span slot="viewport" class="flicking-arrow-next is-circle"></span> -->
          <div slot="viewport" class="flicking-pagination"></div>
        </flicking>
        <!-- <div class="gradient-left-panel-pc"></div>
        <div class="gradient-right-panel-pc"></div> -->
      </div>

      <!-- <div class="section-pc-slider">
        <div class="slider-pc-container">
          <flicking class="" :options="flickerOptionsPC" >
            <div @click="visiblePopupSale = !visiblePopupSale" class="panel-pc">
              <img src="https://dodopizza-a.akamaihd.net/static/Img/Banners/g_1632148415_c7ca873baade42e7bd9e71b3baa37e27.jpeg" alt="">
            </div>
            <div class="panel-pc">
              <img src="https://dodopizza-a.akamaihd.net/static/Img/Banners/g_1632148415_c7ca873baade42e7bd9e71b3baa37e27.jpeg" alt="">
            </div>
            <div class="panel-pc">
              <img src="https://dodopizza-a.akamaihd.net/static/Img/Banners/g_1632148415_c7ca873baade42e7bd9e71b3baa37e27.jpeg" alt="">
            </div>
          </flicking>
        </div>
      </div> -->
      <div class="content_pc container">
        <div class="clinic_block_pc">
          <div class="rating__block mobile">
            <!-- <div class="rating_img">
              <div class="rating_imgback"></div>

              <div class="sity">
                <i class="material-icons-round">location_on</i>Тында
              </div>
            </div> -->
            <div class="rating" style="display: flex">
              <div class="rating_img rating_img_pc" style="padding: 16px;">
                <div class="rating_imgback">
              </div>
              <div class="sity">
                <i class="material-icons-round">location_on</i>Тында
              </div>
            </div>
            <div class="clinic_block_pc_right" style="">
              <div class="block_star" @click="popupOpen()">
                <div class="block_star_cover">
                  <div class="star">
                    <StarRating
                      :rating="rating"
                      style="line-height: 0.9"
                      :read-only="true"
                      text-class="vsr_number"
                      :star-size="17"
                      :increment="0.01"
                      :star-points="[
                        23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                        34, 46, 19, 31, 17,
                      ]"
                    ></StarRating>
                    <!-- <div class="stars_outher" value="1" name="rating">
                      <div
                        class="stars_inner "
                        id="stars_inner"
                        value="1"
                        name="rating"
                      ></div>
                    </div> -->
                  </div>
                  <!-- <span id="number_rating" class="number_rating"></span> -->
                  <span class="rating_point">•</span>
                  <div class="star_support">
                    <span id="num">{{ countStar }} {{ $sklonyator(countStar, ['оценка', 'оценки', 'оценок']) }}</span>
                  </div>
                </div>
                <button ontouchstart="" class="button_star">i</button>
              </div>
              <transition name="fade">
                <div class="container_popup" v-if="popup">
                  <div class="content_popup">
                    <div class="title_popup">Рейтинг</div>
                    <div class="text_popup">
                      Формируется в реальном времени на основе оценок пациентов
                      после каждого приёма
                    </div>
                    <div class="footer_popup">Данные за последние 3 месяца</div>
                  </div>
                  <div ontouchstart="" class="close_popup" @click="popupOpen()">
                    <div class="close"></div>
                  </div>
                </div>
              </transition>
              <div class="block_info_clinic">
                <div class="time_work_title">
                  ул.Красноармейская 11 (3 этаж)<br><br>
                  <span class="time_work"
                    >пн-пт: 09:00 - 18:00<br> сб: 10:00 - 15:00</span
                  >
                </div>
                <div class="button_block">
                  <a style="width: 50%; margin-right: 14px;" ontouchstart="" href="tel:88002002705">
                  <button class="button_call" href="tel:88002002705">
                    Записаться на приём
                  </button>
                </a>
                <a target="_blank" style="width: 50%" ontouchstart="" href="https://yandex.ru/maps/-/CCUqzIaQdA">
                  <button class="button_map">
                    Показать на карте
                  </button>
                </a>
                </div>
              </div>
              </div>
              <!--<div class="background_img"></div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="doctor_block_pc container">
          <div class="doctor info_block" style="height: 100%; padding-bottom: 0px;">
            <div class="doctor_header">
              <div class="doctor_header_experience">Мед. стаж 25 лет</div>
              <div class="doctor_header_experience" style="background-color: #e9f5ff;  color: #004680">Приём взрослых и детей</div>
            </div>
            <div class="doctor_body_profile">
              <div class="doctor_photo">
                <div class="doctor_photo_avatar"></div>
              </div>
              <div class="doctor_info">
                <div class="doctor_fio">Дегтярёва Татьяна Владимировна</div>
                <div class="doctor_status">Руководитель • Зубной врач</div>
              </div>
            </div>
            <div class="doctor_footer">
              <div class="doctor_awards" >
                <Accordion>
                <accordion-item>
                  <template slot="accordion-trigger">
                    <p style="font-size: 18px">Подробнее о специалисте</p>
                  </template>
                  <template slot="accordion-content">
                    <span style="font-size: 18px"
                      >Общий медицинский стаж 25 лет.
                      Из них 17 лет в должности медсестры в отделении реанимации
                      и 8 лет врачом-стоматологом. <br />
                      Пройдено более 15 дополнительных, образовательных
                      мероприятий по направлению стоматологии, в том числе в
                      Москве и Санкт-Петербурге.
                    </span>
                  </template>
                </accordion-item>
              </Accordion>
              </div>
            </div>
          </div>
          <div class="container info_block" style="padding-bottom: 0px">
          <div data-v-78279a3e="" class="zagolovok">Квокка отвечает</div>
          <div>
            <div id="faq">
                <Accordion>
                  <accordion-item v-for="faq in faq_data" :key="faq.id">
                    <template slot="accordion-trigger">
                      <p>{{ faq.title }}</p>
                    </template>
                    <template slot="accordion-content">
                      <span>{{ faq.text }}</span>
                    </template>
                  </accordion-item>
                </Accordion>
              </div>
          </div>
        </div>
        </div>
        
      <div class="content_mobile">
        <div class="container">
          <div class="rating__block mobile">
            <div class="rating_img">
              <div class="rating_imgback"></div>

              <div class="sity">
                <i class="material-icons-round">location_on</i>Тында
              </div>
            </div>
            <div class="rating">
              <div class="block_star" @click="popupOpen()">
                <div class="block_star_cover">
                  <div class="star">
                    <StarRating
                      :rating="rating"
                      style="line-height: 0.9"
                      :read-only="true"
                      text-class="vsr_number"
                      :star-size="17"
                      :increment="0.01"
                      :star-points="[
                        23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                        34, 46, 19, 31, 17,
                      ]"
                    ></StarRating>
                    <!-- <div class="stars_outher" value="1" name="rating">
                      <div
                        class="stars_inner "
                        id="stars_inner"
                        value="1"
                        name="rating"
                      ></div>
                    </div> -->
                  </div>
                  <!-- <span id="number_rating" class="number_rating"></span> -->
                  <span class="rating_point">•</span>
                  <div class="star_support">
                    <span id="num">{{ countStar }} {{ $sklonyator(countStar, ['оценка', 'оценки', 'оценок']) }}</span>
                  </div>
                </div>
                <button ontouchstart="" class="button_star">i</button>
              </div>
              <transition name="fade">
                <div class="container_popup" v-if="popup">
                  <div class="content_popup">
                    <div class="title_popup">Рейтинг</div>
                    <div class="text_popup">
                      Формируется в реальном времени на основе оценок пациентов
                      после каждого приёма
                    </div>
                    <div class="footer_popup">Данные за последние 3 месяца</div>
                  </div>
                  <div ontouchstart="" class="close_popup" @click="popup = !popup">
                    <div class="close"></div>
                  </div>
                </div>
              </transition>
              <div class="block_info_clinic">
                <div class="time_work_title">
                  ул.Красноармейская 11 (3 этаж)<br><br>
                  <span class="time_work"
                    >пн-пт: 09:00 - 18:00<br> сб: 10:00 - 15:00</span
                  >
                </div>
                <a style="display: block; margin-bottom: 10px" ontouchstart="" href="tel:88002002705">
                  <button class="button_call" href="tel:88002002705">
                    Записаться на приём
                  </button>
                </a>
                <a target="_blank" ontouchstart="" href="https://yandex.ru/maps/-/CCUqzIaQdA">
                  <button class="button_map">
                    Показать на карте
                  </button>
                </a>
              </div>
              <!--<div class="background_img"></div>-->
            </div>
          </div>
          <!-- <div class="info_block">
            <div class="">
              <div class="bodyslider">
                <div class="tab_block">
                  <div class="tabs">
                    <input
                      class="zapis_priem"
                      type="radio"
                      id="radio-1"
                      name="tabs"
                      checked
                    />
                    <label class="tab tab1" for="radio-1"
                      >Запись на приём</label
                    >
                    <input
                      class="adres"
                      type="radio"
                      id="radio-2"
                      name="tabs"
                    />
                    <label class="tab tab2" for="radio-2">Адрес</label>
                    <span class="glider"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="block">
                <div class="phone__block">
                  <div class="block_text_phone">
                    <div class="call_to_action">
                      Запишитесь на приём,<br />позвонив на бесплатный номер
                    </div>
                    <div class="phone_text">8 800 200-27-05</div>
                  </div>
                  <a ontouchstart="" href="tel:88002002705">
                    <button class="button_call" href="tel:88002002705">
                      Позвонить
                    </button>
                  </a>
                </div>
                <div class="adres__block">
                  <p class="adres">
                    ул.Красноармейская 11 (3 этаж)<br />Вход с торца здания
                  </p>
                  <div class="maps_yandex">
                     <iframe
                      src="https://yandex.ru/map-widget/v1/?um=constructor%3A1a65a0381c0046a4d526168f6e596efb2231f3f8bb35df872735f92122f0ba37&amp;source=constructor"
                      width="100%"
                      height="350"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- ЗАКРРЫВАЕМ info_block -->
        </div>
        <div class="container doctor info_block" style="padding-bottom: 0">
          <div class="doctor_header">
              <div class="doctor_header_experience">Мед. стаж 25 лет</div>
              <div class="doctor_header_experience" style="background-color: #e2effa;  color: #004680">Приём взрослых и детей</div>
            </div>
          <div class="doctor_body_profile">
            <div class="doctor_photo">
              <div class="doctor_photo_avatar"></div>
            </div>
            <div class="doctor_info">
              <div class="doctor_fio">Дегтярёва Татьяна Владимировна</div>
              <div class="doctor_status">Руководитель • Зубной врач</div>
            </div>
          </div>
          <div class="doctor_footer">
            <div class="doctor_awards">
              <Accordion>
                <accordion-item style="border-bottom: none">
                  <template slot="accordion-trigger">
                    <p>Подробнее о специалисте</p>
                  </template>
                  <template slot="accordion-content">
                    <span
                      >Общий медицинский стаж 25 лет. <br />
                      Из них 17 лет в должности медсестры в отделении реанимации
                      и 8 лет врачом-стоматологом. <br /><br />
                      Пройдено более 15 дополнительных, образовательных
                      мероприятий по направлению стоматологии, в том числе в
                      Москве и Санкт-Петербурге.
                    </span>
                  </template>
                </accordion-item>
              </Accordion>
            </div>
          </div>
        </div>
        <div>
          <div class="container info_block" style="padding-bottom: 0">
            <div class="zagolovok">Квокка отвечает</div>
            <div class="">
              <!-- FAQ -->
              <div id="faq">
                <Accordion>
                  <accordion-item v-for="faq in faq_data" :key="faq.id">
                    <template slot="accordion-trigger">
                      <p>{{ faq.title }}</p>
                    </template>
                    <template slot="accordion-content">
                      <span>{{ faq.text }}</span>
                    </template>
                  </accordion-item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="margin-top: 50px">
        <div
          class="info_block"
          style="position: absolute; top: -45px; z-index: 9"
        >
          <div class="zagolovok">Адрес Квокки</div>
        </div>
      </div>
      <!-- <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A1a65a0381c0046a4d526168f6e596efb2231f3f8bb35df872735f92122f0ba37&amp;source=constructor"
          width="100%"
          height="250"
          frameborder="0">
        </iframe> -->
      <!-- <div id="map" style="width: 100%; height: 300px"></div> -->
      <yandex-map
        :coords="carta.coords"
        style="width: 100%; height: 300px"
        :controls="carta.controls"
      >
        <ymap-marker
          marker-id="1"
          marker-type="placemark"
          :coords="carta.coords"
          :icon="carta.markerIcon"
        ></ymap-marker>
      </yandex-map>
      <div class="footer">
        <div class="footer_email">
          <div class="container" style="margin-top: 0">
            <span class="footer_email_title "
            >г.Тында ул.Красноармейская 11 (3 этаж)</span>
          </div>
        </div>
        <div class="footer_navigation container">
          <div class="footer_navigation_section">
            <div class="footer_navigation_title">Контакты</div>
            <a class="footer_navigation_item" href="tel:88002002705">8 800 200-27-05</a>
            <a class="footer_navigation_item" href="mailto:info@kvokka-stom.ru">info@kvokka-stom.ru</a>
          </div>
          <div class="footer_navigation_section">
            <div class="footer_navigation_title">Документы</div>
            <a href="/documents/license.pdf" target="_blank" class="footer_navigation_item"
              ><i class="material-icons-round footer_navigation_icon"
                >description</i
              >Лицензия</a
            >
            <a
              href="/documents/privat-policy.pdf"
              target="_blank"
              class="footer_navigation_item"
              ><i class="material-icons-round footer_navigation_icon"
                >description</i
              >Политика конфиденциальности</a
            >
          </div>
          <div class="logo_footer"></div>
        </div>
        <div class="container">
          <div class="footer_info">
            <div class="sprafka">
              <!-- <a
                  href="/documents/privat-policy.pdf"
                  target="_blank"
                  class="politic_footer"
                  >Политика конфиденциальности</a
                > -->
              <div class="ip_footer">
                ИП Дегтярёва Т.В. ИНН 280108263611 ОГРНИП 321280100005681
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="cookie">
        <div class="cookie_block">
          <div class="cookie_block_content">
            <div class="cookie_text">
              Оставаясь на сайте, вы соглашаетесь с
              <a href="/documents/privat-policy.pdf" target="_blank"
                >политикой применения</a
              >
              файлов cookies
            </div>
            <button href="javascript:void(0);" class="close_cookie_block">
              ок
            </button>
          </div>
        </div>
      </div> -->
    </div>
    <div style="position: relative">
      <div>
        <transition name="fade">
          <div v-if="visiblePopupSale" class="popup_sale" style="z-index: 1000">
            <i
              @click="visiblePopupSale = !visiblePopupSale"
              class="material-icons-round popup_sale_close"
              >close</i
            >
            <div class="popup_sale_content">
              <!-- <div class="popup_sale_pagination">{{ paginationNum }}</div> -->
              <flicking
                class=""
                style="height: calc(100% + 10px); padding: 26px 0 10px"
                :plugins="plugins"
                :options="sliderPopup_sale"
              >
                <div v-for="popup in banners_mobile" :key="popup.id" class="panel_popup_sale">
                  <div class="panel_popup_sale_content">
                    <div class="panel_popup_sale_content_header">
                      <img
                        class="popup_sale_img"
                        :src="popup.link_pic"
                        alt=""
                      />
                      <div class="popup_sale_title">{{ popup.title }}</div>
                      <div class="popup_sale_text">
                        <p>{{ popup.text_body }}</p>
                        <!-- <a
                          target="_blank"
                          href="https://star-smile.ru"
                          style="
                            display: block;
                            margin-top: 10px;
                            color: #004680;
                          "
                          >Подробнее на сайте Star Smile</a
                        > -->
                      </div>
                    </div>
                    <div class="panel_popup_sale_content_footer">
                      <div class="popup_sale_call_to_action">
                        <a ontouchstart="" href="tel:88002002705">
                          <button class="button_call" href="tel:88002002705">
                            Записаться на приём
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  slot="viewport"
                  class="flicking-pagination popup_sale_pagination"
                ></div>
              </flicking>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div style="position: relative">
      <div>
        <transition name="fade">
          <div v-if="modal_open" class="modal modal-standard modal-open">
            <div class="content-table">
              <div class="content-cell">
                <div class="content-wrapper">
                  <div class="content-padding">
                    <div class="modal-content">
                      <div class="grid-item-modal">
                        <!-- style="height: calc(100% + 10px); padding: 26px 0 10px"
                          :plugins="plugins" -->
                        <!-- <flicking style="width: 100%"
                          class="flickingModalMobile"
                          :options="sliderModal"
                        > -->
                          <div class="modal-panel">
                            <div :model="data.dataPopupBanners" class="grid-item-modal-content">
                              <h1 class="typography-eyebrow-reduced">{{ dataPopupBanners.title }}</h1>
                              <p>{{dataPopupBanners.text_body}}</p><br>
                              <a style="color: blue" target="_blank" :href="dataPopupBanners.link">{{ dataPopupBanners.link_title }}</a>
                            </div>
                          </div>
                          <!-- <div style="width: 100%; height: 100%;" class="modal-panel"> 
                              <div class="grid-item-modal-content">
                              <h1 class="typography-eyebrow-reduced">Эффективный теплообмен</h1>
                              <p>Благодаря беспрецедентной эффективности чипа&nbsp;M1, MacBook&nbsp;Air обеспечивает высокую производительность без&nbsp;охлаждающего вентилятора. Всё тепло, вырабатываемое системой, берёт на&nbsp;себя алюминиевый теплоотвод. Поэтому, как&nbsp;бы активно ни&nbsp;работал MacBook&nbsp;Air, он&nbsp;делает это бесшумно.</p>
                            </div>
                          </div> -->
                        <!-- </flicking> -->
                        <div class="modal-close-sticky">
                          <button @click="modalOpen()" class="modal-close-circle" data-modal-close="" aria-label="закрыть окно с&nbsp;информацией о&nbsp;теплообмене">
                            <svg class="close-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                            <g transform="matrix(1 0 0 1 58.93574707031257 115.221435546875)">
                              <path d="M-40.9-81.2c8.8,0,16-7.2,16-16c0-8.8-7.2-16-16-16c-8.8,0-16,7.2-16,16C-56.9-88.5-49.7-81.2-40.9-81.2z
                                M-40.9-83.3c-7.7,0-13.9-6.2-13.9-13.9c0-7.7,6.2-13.9,13.9-13.9c7.7,0,13.9,6.2,14,13.9C-27-89.5-33.2-83.3-40.9-83.3z
                                M-46.5-90.6c0.3,0,0.5-0.1,0.7-0.3l4.8-4.8l4.8,4.8c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,1-0.4,1-1c0-0.3-0.1-0.5-0.3-0.7l-4.8-4.8
                                l4.9-4.9c0.2-0.2,0.3-0.4,0.3-0.7c0-0.6-0.4-1-1-1c-0.3,0-0.5,0.1-0.7,0.3l-4.9,4.8l-4.8-4.8c-0.2-0.2-0.4-0.3-0.7-0.3
                                c-0.6,0-1,0.4-1,1c0,0.3,0.1,0.5,0.3,0.7l4.8,4.8l-4.8,4.9c-0.2,0.2-0.3,0.4-0.3,0.7C-47.5-91.1-47.1-90.6-46.5-90.6z"></path>
                            </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div v-if="cookieVisible" class="popup-policy" id="cookie_note">
      <span class="policy__container">Продолжая пользоваться сайтом, вы принимаете <a class="cookie-policy__link" target="_blank" href="https://kvokka-stom.ru/documents/privat-policy.pdf" data-type="primary">условия обработки персональных данных</a>
        <button type="button" @click="setCookies()" class="cookie-policy__button cookie_accept" id="cookie_accept">✕</button>
      </span>
    </div>
  </div>
</template>

<script>
// import { defineComponent } from 'vue'
// import CookieLaw from 'vue-cookie-law'
import { mapState, mapActions } from "vuex";
import StarRating from "vue-star-rating";
import Accordion from "../components/accordion/accordion.vue";
import AccordionItem from "../components/accordion/accordion-item.vue";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { Pagination, Arrow, Fade } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
// import cookie from 'vue-cookie'
// import "../assets/plugins/cookie"
// import "@egjs/flicking-plugins/dist/arrow.css";

// const flicking = new Flicking("#flick", {circular: true})
// flicking.addPlugins(new Arrow())

export default {
  data() {
    return {
      cookieVisible: false,
      info: 'null',
      popup: false,
      data: {},
      flickerOptions: {
        bound: true,
        duration: 370,
        threshold: 50,
        preventClickOnDrag: true,
        align: "prev",
        deceleration: 0.0085,
      },
      flickerOptionsPC: {
        bound: true,
        circular: true,
        duration: 370,
        threshold: 50,
        preventClickOnDrag: true,
        align: "center",
        deceleration: 0.0085,
      },
      plugins_pc: [new Pagination({ type: "bullet" }), new Fade()],
      // plugins: [new Pagination({ type: "fraction" })],
      // sliderPopup_sale: {
      //   defaultIndex: 0,
      //   duration: 370,
      //   threshold: 50,
      //   preventClickOnDrag: true,
      //   deceleration: 0.0085,
      //   defaultIndex: ''
      // },
      // sliderModal: {
      //   defaultIndex: 0,
      //   duration: 370,
      //   threshold: 50,
      //   preventClickOnDrag: true,
      //   deceleration: 0.0085,
      //   defaultIndex: '',
      //   align: "prev",
      //   // adaptive: true,
      // },
      visiblePopupSale: false,
      modal_open: false,
      opacityStyle: 1,
      carta: {
        coords: [55.14827789, 124.725051],
        controls: [],
        markerIcon: {
          layout: "default#imageWithContent",
          imageHref: "https://kvokka-stom.ru/static/img/PointKvokka-22.svg",
          imageSize: [38, 60],
          imageOffset: [-5, -60],
          content: "3 этаж",
          contentOffset: [38, 10],
          contentLayout:
            '<div style="background: white; width: 50px; color: #black; font-family: CirceRoundedRegular";>$[properties.iconContent]</div>',
        },
      },
      dataPopupBanners: []
      // loading_banner_complete: false
      // paginationNum: 3,
    };
  },
  components: {
    StarRating,
    Accordion,
    AccordionItem,
    yandexMap,
    ymapMarker,
    // CookieLaw,
    // cookie
  },
  methods: {
    popupOpen() {
      if (this.popup) {
        this.popup = false;
      } else this.popup = true;
    },
    // popupBanner(banners_pc) {
    //   this.banners_mobile = banners_pc.serial_number
    //   // this.sliderPopup_sale.defaultIndex = defaultIndex
    //   this.visiblePopupSale = !this.visiblePopupSale
    // },
    modalOpen(banner) {
      // this.$store.dispatch("BANNERS_MOBILE", banners_pc.id)
      this.dataPopupBanners = banner
      this.modal_open = !this.modal_open
    },
    setCookies() {
      this.$cookie.set('test', 'Hello world!', 31)
      this.cookieVisible = false
      // this.cookieVisible = true
      // if (!this.$cookie.get(this.test)) {
      //     // this.$cookie.set('test', 'Hello world!', 31)
      //     this.cookieSuccess = false
      // }
      // else {
      //   this.cookieSuccess = true
      // } 
      // this.$cookies.set('privat-policy', 'value', 31)
    },
    // delCookie() {
    //   this.$cookie.delete('test')
    // },
    getCookie() {
      this.info = 'get нача'
      // this.cookieVisible = true
      if (!this.$cookie.get('test')) {
          // this.$cookie.set('test', 'Hello world!', 31)
          this.cookieVisible = true
          this.info = 'get ушел на if'
      } else  this.info = 'get исполнен по else'
      // if (this.$cookie.get(test)) {
      //     // this.$cookie.set('test', 'Hello world!', 31)
      //   this.cookieVisible = true
      // }
    }
  },
  mounted() {
    this.$store.dispatch("NPS_RATING");
    this.$store.dispatch("BANNERS_HEADER");
    // this.$store.dispatch("BANNERS_MOBILE");
    this.$store.dispatch("FAQ");
    this.getCookie()
  },
  computed: {
    ...mapState({
      rating: (state) => state.rating,
      countStar: (state) => state.countStar,
      banners_header: (state) => state.banners_header,
      // banners_mobile: (state) => state.banners_mobile,
      loading_banner_complete: (state) => state.loading_banner_complete,
      // loading_bannerMobile_complete: (state) => state.loading_bannerMobile_complete,
      faq_data: (state) => state.faq
    }),
    // getCookie() {
    //   if (!this.$cookie.get(this.test)) {
    //       this.cookieSuccess = false
    //       console.log('good')
    //   }
    //   else {
    //     this.cookieSuccess = true
    //   }
    // }
  },
};
</script>

<style  scoped>
@import "../assets/css/main.css";
@import "../assets/css/normalize.css";
/* @import "node_modules/@egjs/vue3-flicking/dist/flicking.css"; */
/* @import url("/node_modules/@egjs/vue-flicking/dist/flicking.css"); */
</style>

<style scoped src="../assets/css/policy-popup.css"></style>
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import { filter } from 'core-js/core/array'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        rating: null,
        countStar: null,
        banners_header: [],
        // banners_mobile: [],
        loading_banner_complete: false,
        // loading_bannerMobile_complete: false,
        faq: [],
    },
    mutations: {
        NPS_RATING(state, response) {
            state.rating = response.Rating.toFixed(2)
            state.countStar = response.Count
        },
        BANNERS_HEADER(state, responce) {
            state.banners_header = responce
        },
        // BANNERS_MOBILE(state, filterMobile) {
        //     state.banners_mobile = filterMobile
        // },
        LOADING_STATUS_BANNERS(state, loading_banner_complete) {
            state.loading_banner_complete = loading_banner_complete
        },
        // LOADING_STATUS_MOBILE_BANNERS(state, loading_banner_mobile_complete) {
        //     state.loading_bannerMobile_complete = loading_banner_mobile_complete
        // },
        FAQ(state, response) {
            state.faq = response
        }
    },
    actions: {
        async NPS_RATING({ commit }) {
            let res = await axios.get('https://api.kvokka-stom.ru/v1/nps/rating')
            let response = res.data
            commit('NPS_RATING', response)
        },
        async BANNERS_HEADER({commit}) {
            commit('LOADING_STATUS_BANNERS', false)
            let res = await axios.get('https://api.kvokka-stom.ru/v1/banners')
            let responce = res.data
            commit('BANNERS_HEADER', responce)
            setTimeout(() => {
                commit('LOADING_STATUS_BANNERS', true)
                }, 500)
        },
        // async BANNERS_PC({commit}) {
        //     commit('LOADING_STATUS_BANNERS', false)
        //     let res = await axios.get('https://api.kvokka-stom.ru/v1/bannersfilter')
        //     let response = res.data
        //     let filterPC = response.filter(banner => banner.device_type.includes('pc'))
        //     // console.log(filterRes)
        //     commit('BANNERS_PC', filterPC)
        //     setTimeout(() => {
        //         commit('LOADING_STATUS_BANNERS', true)
        //         }, 500)
        // },
        // async BANNERS_MOBILE({ commit }) {
        //     commit('LOADING_STATUS_MOBILE_BANNERS', false)
        //     let res = await axios.get('https://api.kvokka-stom.ru/v1/bannersfilter')
        //     let response = res.data
        //     let filterMobile = response.filter(banner => banner.device_type.includes('mobile'))
        //     // console.log(filterMobile)
        //     commit('BANNERS_MOBILE', filterMobile)
        //     setTimeout(() => {
        //         commit('LOADING_STATUS_MOBILE_BANNERS', true)
        //         }, 10)
        // },
        async FAQ({ commit }) {
            // commit('LOADING_STATUS_FAQ', false)
            let res = await axios.get('https://api.kvokka-stom.ru/v1/faq')
            let response = res.data
            commit('FAQ', response)
            // setTimeout(() => {
            //     commit('LOADING_STATUS_FAQ', true)
            //     }, 10)
        },
    },
    getters: {
        BANNERS_PC(state) {
            return state.banners_header
        },
        // BANNERS_MOBILE(state) {
        //     return state.banners_mobile
        // },
    }
})
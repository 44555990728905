import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueFlicking from "@egjs/vue-flicking"
import sklonyator from './assets/plugins/sklonyator'
// import YmapPlugin from 'vue-yandex-maps'

const VueCookie = require('vue-cookie')

// CSS
import '/src/assets/css/material-icons-round.css'
import '/src/assets/css/flicking.css'

// const settings = {
//     apiKey: 'c48198d3-9359-4a75-8e33-8c7bf30599a0',
//     lang: 'ru_RU',
//     coordorder: 'latlong',
//     version: '2.1'
// }

Vue.component('v-style', { render: function(createElement) { return createElement('style', this.$slots.default) } })

// Vue.use(YmapPlugin, settings)
Vue.use(VueFlicking)
Vue.use(sklonyator)
Vue.use(VueCookie)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')